<template>
  <div>
    <b-card
      title="Yazışmalar"
    >
      <div>
        <b-row>
          <b-col>
            <b-form-group
              label="Not Ekle"
              label-for="notes"
            >
              <b-form-textarea
                id="notes"
                v-model="messageNote"
                placeholder="Giriniz."
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right mb-2">
            <b-button
              variant="primary"
              :disabled="!messageNote"
              @click="sendNote"
            >
              <feather-icon icon="SendIcon" />
              Gönder
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div
        v-if="projectNotes.length > 0"
        style="height: 50vh; overflow-y: auto;"
      >

        <div
          v-for="(item,key) in projectNotes"
          :key="key"
          :class="item.id_users === userData.id ? 'd-flex w-100 justify-content-end' : 'd-flex w-100'"
        >
          <div :class="item.id_users === userData.id ? 'w-75 bg-light-warning p-2 rounded mb-2' : 'w-75 bg-light-secondary p-2 rounded mb-2' ">
            <div class="font-small-2 mb-1">
              <b-row>
                <b-col md="10">
                  <h5>{{ item.username }}</h5>
                  <div><small class="text-muted">{{ item.user_type }}</small></div>
                </b-col>
                <b-col
                  v-if="item.id_users === userData.id"
                  md="2"
                >
                  <b-button
                    variant="outline-danger"
                    size="sm"
                    pill
                    class="btn-icon btn-sm  ml-2 float-right"
                    @click="removeData(item.id)"
                  >
                    <FeatherIcon icon="TrashIcon" />
                  </b-button>
                </b-col>
              </b-row>
            </div>
            <div>{{ item.content }}</div>
            <div class="mt-1">
              <small class="text-muted">{{ moment(item.created).format('llll') }} tarihinde oluşturuldu.</small>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <b-alert
          show
          variant="info"
        >
          <div class="alert-body text-center">
            <div class="lead">
              <feather-icon icon="InfoIcon" />
              <span> Kayıt bulunamadı.</span>
            </div>
          </div>
        </b-alert>
      </div>
    </b-card>
  </div>
</template>
<script>

import {
  BCard, BRow, BCol, BButton, BFormGroup, BFormTextarea, BAlert,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'ProjectNotesCard',
  components: {
    BAlert,
    BFormTextarea,
    BFormGroup,
    BButton,
    BCard,
    BRow,
    BCol,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      messageNote: null,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['projects/dataItem']
    },
    projectNotes() {
      return this.$store.getters['projectNotes/dataList']
    },
    saveData() {
      return this.$store.getters['projectNotes/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
        this.setNotes()
        this.messageNote = null
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.submitStatus = false
    },
  },
  created() {
    this.setNotes()
  },
  methods: {
    setNotes() {
      this.$store.dispatch('projectNotes/getDataList', {
        select: [
          'project_notes.id as id',
          'project_notes.content as content',
          'project_notes.created as created',
          'user_types.title as user_type',
          'users.name as username',
          'project_notes.id_users as id_users',
        ],
        where: {
          'project_notes.id_projects': this.$route.params.id,
        },
      })
    },
    sendNote() {
      this.$store.dispatch('projectNotes/saveData', {
        content: this.messageNote,
        id_projects: this.$route.params.id,
      })
    },
    removeData(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('projectNotes/removeData', id)
            .then(response => {
              if (response.status) {
                this.$swal({
                  icon: 'success',
                  title: this.$store.state.app.removeResultTitle,
                  text: this.$store.state.app.removeResultMessage,
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              } else {
                this.$swal({
                  icon: 'warning',
                  title: 'İşlem Başarısız',
                  text: response.message,
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
