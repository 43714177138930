<template>
  <div>
    <b-card
      title="Durum Geçmişi"
      no-body
    >
      <b-card-header>
        <b-card-title>
          Durum Geçmişi
        </b-card-title>
      </b-card-header>
      <b-card-body>
        <b-card-text>
          Projeye ait durum geçmişi aşağıdaki tabloda listelenir.
        </b-card-text>
      </b-card-body>
      <b-table
        v-if="dataItem.logs.length > 0"
        responsive="sm"
        :fields="fields"
        :items="dataItem.logs"
        striped
        fixed
      >
        <template #cell(username)="data">
          {{ data.item.username }}
          <div v-if="data.item.user_types">
            <small class="text-primary">{{ data.item.user_types }}</small>
          </div>
        </template>
        <template #cell(created)="data">
          {{ moment(data.item.created).format('DD.MM.YYYY hh:mm:ss') }}
        </template>
      </b-table>
      <b-alert
        v-else
        show
        variant="info"
        class="ml-2 mr-2 text-center"
      >
        <div class="alert-body">
          <FeatherIcon icon="InfoIcon" /> Projeye ait durum güncellemesi bulunmuyor.
        </div>
      </b-alert>
    </b-card>

  </div>
</template>
<script>
import {
  BCard, BCardTitle, BCardHeader, BCardBody, BCardText, BTable, BAlert,
} from 'bootstrap-vue'

export default {
  name: 'LogCard',
  components: {
    BAlert,
    BTable,
    BCard,
    BCardBody,
    BCardText,
    BCardTitle,
    BCardHeader,
  },
  data() {
    return {
      fields: [
        {
          key: 'username',
          label: 'Güncelleyen',
        },
        {
          key: 'project_statuses',
          label: 'Güncel Proje Durumu',
        },
        {
          key: 'created',
          label: 'Güncelleme Tarihi',
        },
      ],
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['projects/dataItem']
    },
  },
}
</script>
