<template>
  <div>
    <b-card title="Proje Bilgileri">
      <b-list-group>
        <b-list-group-item v-if="dataItem.title">
          <div class="font-weight-bold text-primary">
            Proje Adı
          </div>
          <div>
            {{ dataItem.title }}
          </div>
        </b-list-group-item>
        <b-list-group-item v-if="dataItem.pnumber">
          <div class="font-weight-bold text-primary">
            Proje No
          </div>
          <div>
            {{ dataItem.pnumber }}
          </div>
        </b-list-group-item>
        <b-list-group-item v-if="dataItem.username">
          <div class="font-weight-bold text-primary">
            Oluşturan
          </div>
          <div>
            {{ dataItem.username }}
          </div>
        </b-list-group-item>
        <b-list-group-item v-if="dataItem.idate">
          <div class="font-weight-bold text-primary">
            Görüşme Tarihi
          </div>
          <div>
            {{ moment(dataItem.idate).format('LL') }}
          </div>
        </b-list-group-item>
        <b-list-group-item v-if="dataItem.idate">
          <div class="font-weight-bold text-primary">
            Proje Talep Tarihi
          </div>
          <div>
            {{ moment(dataItem.pdate).format('LL') }}
          </div>
        </b-list-group-item>
        <b-list-group-item>
          <div class="font-weight-bold text-primary">
            Toplam Proje Alanı (m2)
          </div>
          <div>
            <b-input-group>
              <cleave
                id="m2"
                v-model="dataItem.m2"
                class="form-control"
                :raw="true"
                :options="options.float"
                placeholder="M2 Alanı"
              />
              <template #append>
                <b-button
                  variant="primary"
                  @click="saveM2"
                >
                  <feather-icon icon="SaveIcon" />
                </b-button>
              </template>
            </b-input-group>
          </div>
        </b-list-group-item>
        <b-list-group-item v-if="dataItem.x_area">
          <div class="font-weight-bold text-primary">
            Alanın En Ölçüsü
          </div>
          <div>
            {{ dataItem.x_area | toNumber }} cm
          </div>
        </b-list-group-item>
        <b-list-group-item v-if="dataItem.y_area">
          <div class="font-weight-bold text-primary">
            Alanın Boy Ölçüsü
          </div>
          <div>
            {{ dataItem.y_area | toNumber }} cm
          </div>
        </b-list-group-item>
        <b-list-group-item v-if="dataItem.z_area">
          <div class="font-weight-bold text-primary">
            Alanın Yükseklik Ölçüsü
          </div>
          <div>
            {{ dataItem.z_area | toNumber }} cm
          </div>
        </b-list-group-item>
        <b-list-group-item v-if="dataItem.x_door">
          <div class="font-weight-bold text-primary">
            Kapının En Ölçüsü
          </div>
          <div>
            {{ dataItem.x_door | toNumber }} cm
          </div>
        </b-list-group-item>
        <b-list-group-item v-if="dataItem.y_door">
          <div class="font-weight-bold text-primary">
            Kapının Boy Ölçüsü
          </div>
          <div>
            {{ dataItem.y_door | toNumber }} cm
          </div>
        </b-list-group-item>
        <b-list-group-item v-if="dataItem.z_door">
          <div class="font-weight-bold text-primary">
            Kapının Yükseklik Ölçüsü
          </div>
          <div>
            {{ dataItem.z_door | toNumber }} cm
          </div>
        </b-list-group-item>
        <b-list-group-item v-if="dataItem.notes">
          <div class="font-weight-bold text-primary">
            Proje Açıklaması
          </div>
          <div>
            {{ dataItem.notes }}
          </div>
        </b-list-group-item>
      </b-list-group>
    </b-card>
  </div>
</template>
<script>

import {
  BListGroup, BListGroupItem, BCard, BInputGroup, BButton,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'

export default {
  name: 'ProjectInfoCard',
  components: {
    BListGroup, BListGroupItem, BCard, BInputGroup, Cleave, BButton,
  },
  data() {
    return {
      options: {
        float: {
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '.',
        },
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['projects/dataItem']
    },
  },
  methods: {
    saveM2() {
      this.$store.dispatch('projects/saveData', {
        id: this.dataItem.id,
        m2: this.dataItem.m2,
      })
    },
  },
}
</script>
