<template>
  <div>
    <h4>Projede Kullanılan Malzemeler</h4>
    <b-table
      responsive="sm"
      :fields="tableFields"
      :items="projectCosts.list"
      striped
    >
      <template #cell(stocks)="data">
        {{ data.item.stocks }}
        <div v-if="data.item.stock_groups">
          <small class="text-primary">{{ data.item.stock_groups }}</small>
        </div>
      </template>
      <template #cell(output_amount)="data">
        {{ data.item.output_amount | toNumber }}
        <span
          v-if="data.item.units"
          class="text-primary"
        > {{ data.item.units }}</span>
      </template>
      <template #cell(amountPrice)="data">
        {{ data.item.amountPrice | toCurrency }}
        <span class="text-primary"> TL</span>
      </template>
      <template #cell(cost)="data">
        {{ data.item.cost | toCurrency }}
        <span class="text-primary"> TL</span>
      </template>
    </b-table>
    <b-row class="mt-3">
      <b-col>
        <p v-if="projectCosts.saveStatus">
          Proje maliyet kaydı <span class="text-primary">{{ moment(projectCosts.list[0].created).format('DD.MM.YYYY') }}</span> tarihinde kaydedilmiştir.
        </p>
        <p><small class="text-muted"><span class="text-primary">*</span>Proje maliyet kayıtlarına işçilik ve yukarıdaki tabloya konu olmayan diğer gider türleri dahil değildir.</small></p>
      </b-col>
      <b-col md="auto">
        <div
          v-if="projectCosts.totalCost"
          class="text-right"
        >
          <div>
            <h4>{{ projectCosts.totalCost | toCurrency }} <span class="text-primary"> TL</span></h4>
            <small>Genel Toplam Proje Maliyeti</small>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BTable, BRow, BCol } from 'bootstrap-vue'

export default {
  name: 'ProjectCostsCard',
  components: { BTable, BRow, BCol },
  data() {
    return {
      tableFields: [
        {
          key: 'stocks',
          label: 'Malzeme',
          thClass: 'text-nowrap',
        },
        {
          key: 'output_amount',
          label: 'Kullanılan Miktar',
        },
        {
          key: 'amountPrice',
          label: 'Maliyet Birim Fiyatı',
        },
        {
          key: 'cost',
          label: 'Toplam Maliyet',
        },
      ],
    }
  },
  computed: {
    projectCosts() {
      return this.$store.getters['projects/costData']
    },
  },
}
</script>
