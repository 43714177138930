<template>
  <div>
    <b-card>
      <b-row>
        <b-col md="3">
          <b-media no-body>
            <b-media-aside
              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="light-info"
              >
                <feather-icon
                  size="24"
                  icon="BoxIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ dataItem.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Proje Kartı
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col
          class="text-right"
        >
          <b-row>
            <b-col>
              <v-select
                v-if="dataItem.id_project_statuses !== '5'"
                id="id_project_statuses"
                v-model="dataItem.id_project_statuses"
                :options="projectStatuses"
                :clearable="false"
                label="title"
                :reduce="item => item.id"
                placeholder="Seçiniz"
                :disabled="dataItem.id_project_statuses === '5' || dataItem.id_project_statuses === '12' ||dataItem.id_project_statuses === '10'"
              >
                <template v-slot:option="option">
                  <option
                    :value="option.id"
                    :disabled="option.hidden !== null"
                  >
                    {{ option.title }}

                  </option>
                </template>
              </v-select>
            </b-col>
            <b-button
              v-if="dataItem.id_project_statuses !== '5'"
              variant="primary"
              class="mr-1"
              :disabled="dataItem.id_project_statuses === '5'"
              @click="updateStatus"
            >
              <feather-icon icon="SaveIcon" />
              Kaydet
            </b-button>
            <b-button
              v-if="dataItem.id_project_statuses === '12' && userData.id_user_types === '1'"
              variant="success"
              class="mr-1"
              @click="openCostModal"
            >
              <feather-icon icon="PieChartIcon" />
              Maliyet Hesapla
            </b-button>
            <b-button
              variant="success"
              class="mr-1"
              @click="projectRevise"
            >
              <feather-icon icon="EditIcon" />
              Revize
            </b-button>

          </b-row>

        </b-col>
      </b-row>
    </b-card>
    <b-alert
      v-if="dataItem.id_projects"
      variant="warning"
      show
    >

      <div class="alert-body text-center">
        <feather-icon icon="InfoIcon" />
        Bu Proje <a
          class="text-primary"
          :href="'/app/projects/view/'+dataItem.id_projects"
        >{{ dataItem.revizedpnumber }}</a> numaralı projeden revize edilmiştir.
      </div>
    </b-alert>
    <b-tabs
      pills
      fill
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="InfoIcon" />
          Proje Talebi
        </template>
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <project-info-card />
          </b-col>
          <b-col
            cols="12"
            md="8"
          >
            <project-notes-card />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="FileTextIcon" />
          Teklifler
        </template>
        <b-card no-body>
          <b-card-header>
            <b-card-title>Teklif Listesi</b-card-title>
            <b-button
              class="float-right"
              variant="primary"
              :disabled="dataItem.id_project_statuses !== '9'"
              :to="'/app/offers/add/'+ dataItem.id"
            >
              <feather-icon icon="PlusIcon" />
              Ekle
            </b-button>
          </b-card-header>
          <offer-list
            :remove-data="removeOfferData"
            :data-list="offerList"
            :fields="offerFields"
          />
        </b-card>
      </b-tab>
      <!-- BU ALAN REVIZE SEBEBI ILE KALDIRILDI. EGER TEKRARDAN AKTIFLESTIRMEK ISTENIRSE BURADAN AKTIFLESTIRILECEK -->
      <!-- BU ALAN REVIZE SEBEBI ILE KALDIRILDI. EGER TEKRARDAN AKTIFLESTIRMEK ISTENIRSE BURADAN AKTIFLESTIRILECEK -->
      <!-- BU ALAN REVIZE SEBEBI ILE KALDIRILDI. EGER TEKRARDAN AKTIFLESTIRMEK ISTENIRSE BURADAN AKTIFLESTIRILECEK -->
      <!-- BU ALAN REVIZE SEBEBI ILE KALDIRILDI. EGER TEKRARDAN AKTIFLESTIRMEK ISTENIRSE BURADAN AKTIFLESTIRILECEK -->
      <!-- BU ALAN REVIZE SEBEBI ILE KALDIRILDI. EGER TEKRARDAN AKTIFLESTIRMEK ISTENIRSE BURADAN AKTIFLESTIRILECEK -->
      <!--<b-tab v-if="false">
        <template #title>
          <feather-icon icon="ListIcon" />
          Ürün Listesi
        </template>
        <b-card v-if="linesData.length > 0">
          <b-row>
            <b-col>
              <div class="text-left">
                <h3>{{ calculateProductTotal | toCurrency }} <span class="text-primary"> {{ dataItem.currency }}</span></h3>
                <h5>Toplam Genel Ürün Tutarı</h5>
              </div>
            </b-col>
            <b-col>
              <div class="text-right">
                <b-row>
                  <b-col>
                    <v-select
                      id="id_currencies"
                      v-model="dataItem.id_currencies"
                      :options="currencies"
                      label="title"
                      :reduce="item => item.id"
                      placeholder="Para Birimi Seçiniz"
                    />
                  </b-col>
                  <b-col md="auto">
                    <b-button
                      variant="primary"
                      :disabled="!dataItem.id_currencies"
                      @click="saveCurrencies"
                    >
                      Kaydet
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </b-card>
        <b-card
          v-else
          no-body
        >
          <b-card-body>
            <b-alert
              variant="warning"
              show
            >
              <div class="alert-body text-center">
                <div class="mb-1">
                  Projeye ait ürün bulunmuyor.
                </div>
                <b-button
                  variant="primary"
                  size="sm"
                  :to="'/app/projects/addproduct/'+ dataItem.id"
                >
                  Ürün Ekle
                </b-button>
              </div>
            </b-alert>
          </b-card-body>
        </b-card>
        <b-row>
          <b-col
            v-for="(item,index) in linesData"
            :key="index"
            md="12"
          >
            <lines-list-card :product-type="index" />
          </b-col>
        </b-row>
      </b-tab> -->
      <!-- BU ALAN REVIZE SEBEBI ILE KALDIRILDI. EGER TEKRARDAN AKTIFLESTIRMEK ISTENIRSE BURADAN AKTIFLESTIRILECEK -->
      <!-- BU ALAN REVIZE SEBEBI ILE KALDIRILDI. EGER TEKRARDAN AKTIFLESTIRMEK ISTENIRSE BURADAN AKTIFLESTIRILECEK -->
      <!-- BU ALAN REVIZE SEBEBI ILE KALDIRILDI. EGER TEKRARDAN AKTIFLESTIRMEK ISTENIRSE BURADAN AKTIFLESTIRILECEK -->
      <!-- BU ALAN REVIZE SEBEBI ILE KALDIRILDI. EGER TEKRARDAN AKTIFLESTIRMEK ISTENIRSE BURADAN AKTIFLESTIRILECEK -->
      <!-- BU ALAN REVIZE SEBEBI ILE KALDIRILDI. EGER TEKRARDAN AKTIFLESTIRMEK ISTENIRSE BURADAN AKTIFLESTIRILECEK -->
      <b-tab>
        <template #title>
          <feather-icon icon="FileTextIcon" />
          Dokümanlar
        </template>
        <b-card no-body>
          <b-card-header>
            <b-card-title>
              Dokümanlar
            </b-card-title>
            <b-button
              variant="primary"
              @click="openDocumentModal"
            >
              <feather-icon icon="PlusIcon" />
              Ekle
            </b-button>
          </b-card-header>
          <b-table
            v-if="documentList.length > 0 "
            responsive="sm"
            :fields="documentFields"
            :items="documentList"
            striped
          >
            <template #cell(title)="data">
              {{ data.item.title }}
            </template>
            <template #cell(username)="data">
              {{ data.item.username }}
              <div v-if="data.item.created">
                <small class="text-warning">
                  {{ moment(data.item.created).format('DD.MM.YYYY') }}
                </small>
              </div>
            </template>
            <template #cell(control)="data">
              <div class="text-right">
                <b-dropdown
                  variant="primary"
                  text="Görüntüle"
                  left
                  split
                  size="sm"
                  @click="showDocument(data.item.fileUrl)"
                >
                  <b-dropdown-item @click="removeDocument(data.item.id)">
                    <feather-icon icon="XIcon" />
                    <span class="align-middle ml-50">Sil</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>

          </b-table>
          <b-alert
            v-else
            show
            variant="info"
            class="mr-2 ml-2"
          >
            <div class="alert-body text-center">
              <div class="lead">
                Doküman kaydı bulunamadı.
              </div>
              <div class="mt-1">
                <b-button
                  variant="info"
                  size="sm"
                  @click="openDocumentModal"
                >
                  Doküman Ekle
                </b-button>
              </div>
            </div>
          </b-alert>
          <b-card-footer>
            <b-pagination
              v-model="documentCurrentPage"
              :total-rows="documentCounts"
              :per-page="documentPerPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-card-footer>
        </b-card>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="ListIcon" />
          Durum Geçmişi
        </template>
        <log-card />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="UserIcon" />
          <span>Müşteri Bilgileri</span>
        </template>
        <customer-info />
        <customer-invoice />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="ClockIcon" />
          <span>Müşteri Geçmişi</span>
        </template>
        <customer-history :customer-id="dataItem.id_customers" />
      </b-tab>
    </b-tabs>
    <b-modal
      v-model="documentModal.status"
      title="Doküman Ekle"
      centered
      no-close-on-backdrop
      no-close-on-esc
    >
      <b-form-group
        label="Başlık"
        label-for="title"
      >
        <b-form-input
          id="title"
          v-model="documentModal.title"
          placeholder="Başlık"
        />
      </b-form-group>
      <b-form-group
        label="Doküman Tipi"
        label-for="id_project_document_types"
      >
        <v-select
          id="id_project_document_types"
          v-model="documentModal.id_project_document_types"
          :options="documentTypes"
          label="title"
          :reduce="item => item.id"
          placeholder="Seçiniz"
        />
        <b-alert
          v-if="documentModal.id_project_document_types === '1'"
          variant="info"
          class="mt-1"
          show
        >
          <div class="alert-body">
            <small>
              Yüklenecek doküman tipi "Render" seçildiği için teklif formlarında gösterilecektir.
            </small>
          </div>
        </b-alert>
      </b-form-group>
      <b-form-group
        label="Dosya Yükle"
        label-for="file-upload"
      >
        <b-form-file
          id="file-upload"
          v-model="documentModal.filename"
          placeholder="Bir dosya seçin..."
          drop-placeholder="Dosyayı buraya bırakın..."
          browse-text="Dosya Seçin"
        />
      </b-form-group>
      <template #modal-footer>
        <b-button
          v-if="!documentModal.loadSpinner"
          @click="documentModal.status = false"
        >
          Kapat
        </b-button>
        <b-button
          variant="success"
          :disabled="!documentModal.title || !documentModal.id_project_document_types || !documentModal.filename || documentModal.loadSpinner"
          @click="uploadDocument"
        >
          <b-spinner
            v-if="documentModal.loadSpinner"
            class="mr-1"
            variant="light"
            small
          />

          <feather-icon
            v-if="!documentModal.loadSpinner"
            icon="UploadIcon"
          />
          <template v-if="!documentModal.loadSpinner">
            Yükle
          </template>
          <template v-else>
            Yükleniyor
          </template>

        </b-button>
      </template>
    </b-modal>
    <b-modal
      v-model="costModal"
      title="Proje Maliyeti"
      size="xl"
      centered
      no-close-on-backdrop
      no-close-on-esc
    >
      <project-costs-card />
      <template #modal-footer>
        <b-button
          v-if="!projectCosts.saveStatus"
          variant="success"
          @click="saveCost"
        >
          <feather-icon icon="SaveIcon" />
          Maliyet Hesabını Kaydet
        </b-button>
        <b-button
          variant="secondary"
          @click="costModal=false"
        >
          Kapat
        </b-button>
      </template>
    </b-modal>

  </div>
</template>
<script>
import {
  BAlert,
  BButton,
  BCardFooter,
  BPagination,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BRow,
  BCol,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BCardText,
  BTab,
  BTabs, BFormInput, BFormGroup, BFormFile, BDropdownItem, BDropdown, BSpinner, /* BCardBody, */
} from 'bootstrap-vue'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import CustomerHistory from '@/views/Admin/Customers/View/CustomerHistory.vue'
import CustomerInvoice from '@/views/Admin/Customers/View/CustomerInvoice.vue'
import CustomerInfo from '@/views/Admin/Customers/View/CustomerInfo.vue'
import ProjectInfoCard from '@/views/Admin/Projects/Components/ProjectInfoCard.vue'
import ProjectNotesCard from '@/views/Admin/Projects/Components/ProjectNotesCard.vue'
// import LinesListCard from '@/views/Admin/Projects/Components/linesListCard.vue'
import ProjectCostsCard from '@/views/Admin/Projects/Components/CostsCard.vue'
import OfferList from '@/views/Admin/Offers/Index/OfferList.vue'
import LogCard from '@/views/Admin/Projects/Components/LogsCard.vue'

export default {
  name: 'ViewProjects',
  components: {
    LogCard,
    OfferList,
    // BCardBody,
    ProjectCostsCard,
    // LinesListCard,
    ProjectNotesCard,
    ProjectInfoCard,
    BDropdown,
    BDropdownItem,
    BFormFile,
    BFormGroup,
    BFormInput,
    CustomerInfo,
    CustomerInvoice,
    CustomerHistory,
    vSelect,
    BCardFooter,
    BButton,
    BTable,
    BPagination,
    BAlert,
    BCardHeader,
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BCardText,
    BTab,
    BTabs,
    BSpinner,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      documentCurrentPage: 1,
      documentPerPage: this.$store.state.app.perPage,
      editModal: false,
      costModal: false,
      documentModal: {
        status: false,
        title: null,
        id_project_document_types: null,
        filename: null,
        loadSpinner: false,
      },
      options: {
        numeral: true,
        numeralDecimalMark: ',',
        delimiter: '.',
      },
      offerFields: [
        {
          key: 'title',
          label: 'TEKLİF',
        },
        {
          key: 'company',
          label: 'FİRMA ADI',
        },
        {
          key: 'offers_status',
          label: 'DURUM',
        },
        {
          key: 'control',
          label: '',
          tdClass: 'text-right',
        },
      ],
      documentFields: [
        {
          key: 'title',
          label: 'Doküman',
          thClass: 'text-nowrap',
        },
        {
          key: 'project_document_types',
          label: 'Doküman Tipi',
          thClass: 'text-nowrap',
        },
        {
          key: 'username',
          label: 'Ekleyen',
        },
        {
          key: 'control',
          label: '',
          thClass: 'text-nowrap width-50',
        },
      ],
      documentdataQuery: {
        where: {
          'project_documents.id_projects': this.$route.params.id,
        },
        limit: this.$store.state.app.perPage,
        start: 0,
      },
      offersCurrentPage: 1,
      offersPerPage: this.$store.state.app.perPage,
      offersDataQuery: {
        select: [
          'offers.id AS id',
          'customers.company AS company',
          'offers.odate AS odate',
          'offers.title AS title',
          'CONCAT(customers.cnumber,"-O",offers.onumber) AS onumber',
          'offer_statuses.title AS offers_status',
          'users.name AS username',
        ],
        where: {
          'offers.id_projects': this.$route.params.id,
        },
        order_by: ['offers.id', 'DESC'],
        limit: this.$store.state.app.perPage,
        start: 0,
      },
    }
  },
  computed: {
    moment() {
      return moment
    },
    currencies() {
      return this.$store.getters['currencies/dataList']
    },
    dataItem() {
      return this.$store.getters['projects/dataItem']
    },
    offerList() {
      return this.$store.getters['offers/dataList']
    },
    offerSaveData() {
      return this.$store.getters['offers/dataSaveStatus']
    },
    offerDataCount() {
      return this.$store.getters['offers/dataCounts']
    },
    documentList() {
      return this.$store.getters['projectDocuments/dataList']
    },
    documentCounts() {
      return this.$store.getters['projectDocuments/dataCounts']
    },
    documentSaveStatus() {
      return this.$store.getters['projectDocuments/dataSaveStatus']
    },
    linesData() {
      return this.$store.getters['projectLines/productList']
    },
    saveData() {
      return this.$store.getters['projects/dataSaveStatus']
    },
    saveCostData() {
      return this.$store.getters['projects/costSaveStatus']
    },
    priceSave() {
      return this.$store.getters['projectLines/priceSaveStatus']
    },
    projectStatuses() {
      return this.$store.getters['projectStatuses/dataList']
    },
    documentTypes() {
      return this.$store.getters['projectDocumentTypes/dataList']
    },
    projectCosts() {
      return this.$store.getters['projects/costData']
    },
    calculateProductTotal() {
      const totalPrice = []
      for (let i = 0; i < this.linesData.length; i += 1) {
        for (let l = 0; l < this.linesData[i].productList.length; l += 1) {
          totalPrice.push(this.linesData[i].productList[l])
        }
      }
      const total = totalPrice.reduce((acc, item) => acc + (parseFloat(item.price) * parseFloat(item.amount)), 0)
      // eslint-disable-next-line no-restricted-globals
      return isNaN(total) ? 0 : total
    },
  },
  watch: {
    offerCurrentPage(val) {
      this.offersDataQuery.start = (val - 1) * this.$store.state.app.perPage
      this.getOffers()
    },
    documentCurrentPage(val) {
      this.documentdataQuery.start = (val - 1) * this.$store.state.app.perPage
      this.getDocuments()
    },
    priceSave(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },

    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
        this.$store.commit('projectLines/RESET_DATA_ITEM')
        this.$store.commit('projectLines/RESET_PRODUCT_DATA')
        this.getData()
        this.getDataList()
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
    offerSaveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.getOffers()
    },
    saveCostData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
        this.openCostModal()
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
    documentSaveStatus(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
        this.documentModal.loadSpinner = false
        this.documentModal.status = false
        this.getDocuments()
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
        this.documentModal.loadSpinner = false
      }
    },
  },
  created() {
    this.getData()
    this.getStatuses()
    this.getDocuments()
    this.getDocumentTypes()
    this.getCurrencies()
    this.$store.commit('projectLines/RESET_DATA_ITEM')
    this.$store.commit('projectLines/RESET_PRODUCT_DATA')
    this.getDataList()
    this.getOffers()
  },
  methods: {
    getData() {
      this.$store.dispatch('projects/getDataItem', this.$route.params.id)
        .then(response => {
          if (response.id_customers) {
            this.getCustomer(response.id_customers)
          }
        })
    },
    getDataList() {
      this.$store.dispatch('projectLines/getProductList', {
        id_projects: this.$route.params.id,
      })
    },
    getOffers() {
      this.$store.dispatch('offers/getDataList', this.offersDataQuery)
    },
    getDocuments() {
      this.$store.dispatch('projectDocuments/getDataList', this.documentdataQuery)
    },
    getCustomer(id) {
      this.$store.dispatch('customers/getDataItem', id)
    },
    getCurrencies() {
      this.$store.dispatch('currencies/getDataList')
    },
    projectRevise() {
      if (this.dataItem) {
        this.$swal({
          title: this.$store.state.app.revisedTitle,
          text: this.$store.state.app.revisedMessage,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$store.state.app.revisedConfirm,
          cancelButtonText: this.$store.state.app.revisedCancel,
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.$store.dispatch('projects/saveData', {
              id: this.dataItem.id,
              id_project_statuses: 4,
            })
              .then(response => {
                if (response) {
                  if (response.status) {
                    this.$router.push(`/app/projects/revised/${this.dataItem.id}`)
                  }
                }
              })
            this.$swal({
              icon: 'success',
              title: this.$store.state.app.revisedResultTitle,
              text: this.$store.state.app.revisedResultMessage,
              confirmButtonText: this.$store.state.app.revisedResultClose,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        })
      }
    },
    getStatuses() {
      this.$store.dispatch('projectStatuses/getDataList', {
        select: [
          'project_statuses.id as id ',
          'project_statuses.title as title ',
          'project_statuses.hidden as hidden ',
        ],
        where: {
          'project_statuses.id !=': 5,
        },
      })
    },
    getDocumentTypes() {
      this.$store.dispatch('projectDocumentTypes/getDataList')
    },
    openDocumentModal() {
      this.documentModal.title = null
      this.documentModal.id_project_document_types = null
      this.documentModal.filename = null
      this.documentModal.status = true
      this.documentModal.loadSpinner = false
    },
    updateStatus() {
      if (this.dataItem) {
        this.$store.dispatch('projects/saveData', {
          id: this.dataItem.id,
          id_project_statuses: this.dataItem.id_project_statuses,
        })
      }
    },
    uploadDocument() {
      if (this.documentModal.status) {
        this.documentModal.loadSpinner = true
        this.$store.dispatch('projectDocuments/saveData', {
          id_projects: this.$route.params.id,
          id_project_document_types: this.documentModal.id_project_document_types,
          title: this.documentModal.title,
          filename: this.documentModal.filename,
        })
      }
    },

    showDocument(url) {
      window.open(url, '_blank')
    },
    removeDocument(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('projectDocuments/removeData', id)
            .then(response => {
              if (response.status) {
                this.$swal({
                  icon: 'success',
                  title: this.$store.state.app.removeResultTitle,
                  text: this.$store.state.app.removeResultMessage,
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              } else {
                this.$swal({
                  icon: 'warning',
                  title: 'İşlem Başarısız',
                  text: response.message,
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
        }
      })
    },
    removeOfferData(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('offers/removeData', id)
            .then(response => {
              if (response.status) {
                this.$swal({
                  icon: 'success',
                  title: this.$store.state.app.removeResultTitle,
                  text: this.$store.state.app.removeResultMessage,
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              } else {
                this.$swal({
                  icon: 'warning',
                  title: 'İşlem Başarısız',
                  text: response.message,
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
        }
      })
    },
    saveCurrencies() {
      this.$store.dispatch('projects/saveCurrencies', {
        id_currencies: this.dataItem.id_currencies,
        id_projects: this.dataItem.id,
      })
        .then(response => {
          if (response.status) {
            this.$store.dispatch('projectLines/getDataList', {
              where: {
                'project_lines.id_projects': this.$route.params.id,
              },
            })
          }
        })
    },
    openCostModal() {
      this.costModal = true
      this.$store.dispatch('projects/getCosts', this.dataItem.id)
    },
    saveCost() {
      this.$swal({
        title: 'Maliyeti Kaydet',
        text: 'Proje maliyetini kaydetmek istiyor musunuz Bu işlem geri alınamayacak ve listelenen veriler proje için maliyet verisi olarak kaydedilecek.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Onayla ve Kaydet',
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('projects/saveCosts', {
            id_projects: this.$route.params.id,
          })
            .then(response => {
              if (response.status) {
                this.$swal({
                  icon: 'success',
                  title: 'Proje Maliyeti Kaydet',
                  text: 'Proje maliyeti başarı ile kayıt edildi.',
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              } else {
                this.$swal({
                  icon: 'danger',
                  title: 'Proje Maliyeti Kaydet',
                  text: response.message,
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
